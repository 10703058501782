export const ageLevel = [
  'infants/toddlers (1-3)',
  'very young learners (4-7)',
  'young learners (8-11)',
  'adolescents (12-17)',
  'young adults (18-35)',
  'middle-aged (35-55)',
  'older learners (55+)',
];
export const agesmLevels = [
  'infants/toddlers',
  'very young learners',
  'young learners',
  'adolescents',
  'young adults',
  'middle-aged',
  'older learners',
];
export const educationLevelArry = [
  'High School',
  'Tesol Certificate',
  "Bachelor's Degree",
  "Master's Degree",
  'Phd or Doctorate',
];
export const classSizeArry = [
  'one-on-one',
  'small (2-10)',
  'average (11-35)',
  'large (36-60)',
  'very large (61+)',
];
export const typeSchoolArry = [
  'Public (government) school',
  'Private school',
  'Private language institute',
  'Public (government) college or university',
  'Private college or university',
  'I teach online with a company',
  'I teach staff at a company',
  'I freelance tutor',
];
export const energyLevelArry = ['Low', 'Medium', 'High'];

export const experLevel = [
  'none',
  '1-3',
  '4-6',
  '7-10',
  '11-15',
  '16-20',
  '20+',
];
export const proficiencyLevel = [
  'A1 (Low Beginner)',
  'A2 (High beginner)',
  'B1 (Low Intermediate)',
  'B2 (High Intermediate)',
  'C1 (Low advanced)',
  'C2 (High advanced)',
];
export const proficiencyLevels = [
  { key: 'A1', value: 'A1 (Low Beginner)' },
  { key: 'A2', value: 'A2 (High beginner)' },
  { key: 'B1', value: 'B1 (Low Intermediate)' },
  { key: 'B2', value: 'B2 (High Intermediate)' },
  { key: 'C1', value: 'C1 (Low advanced)' },
  { key: 'C2', value: 'C2 (High advanced)' },
];
export const LangFocus = ['vocabulary', 'grammar', 'functions'];
export const Skills = ['speaking', 'listening', 'reading', 'writing'];
export const lessonStages = [
  'warm-ups / lead ins',
  'introduction',
  'guided practice',
  'independent practice',
  'assessment',
  'homework',
  'review',
];
export const ideaKinds: string[] = [
  'whole class instruction',
  'students moving around the classroom',
  'individual work',
  'multimedia (video or audio)',
  'pair work',
  'group work',
];
export const MessageString = {
  required: 'this field is required',
  discardText: 'are you sure you want to leave and discard?',
  confirmVerify: 'it works only for verified contributors.',
  draftText: 'are you sure you want to save this draft?',
  adaptText: 'are you sure you want to adapt this activity?',
  adaptWait: "we're getting ready for you...just a moment...",
  lessonEmptyObject: 'please add at least one lesson objective.',
  lessonEmptyVocabulary: 'a finished lesson needs at least one vocabulary.',
  lessonEmptySecton: 'a finished lesson needs at least one activity.',
  sectionEmptyObject: 'select at least one lesson objective for this activity.',
  existUser:
    'this email is already in use. please try it again using another email.',
  selectClassTxt: 'for which class are you doing this adaptation?',
  deleteActivity: 'are you sure you want to delete this activity?',
  buildLessonMsg: 'would you like to begin a lesson plan with this activity?',
  deleteLesson: 'are you sure you want to delete this lesson?',
  gUserTrue: 'you are using Gmail login.',
  gUserFalse: 'you are not using Gmail login.',
  feedbackMessage: 'are you sure to send this feedback now?',
};
export const SpecStr = {
  split_1: ':,',
  split_common: ',',
  draft: 'Drafts',
  bookmark: 'Bookmarked',
};
export const activityTypeArry: string[] = [
  'communicative task',
  'drill',
  'game',
  'presentation',
  'project',
  'prompt',
  'worksheet',
  'song/music',
];
export const timeArry: string[] = [
  'none',
  '5 min',
  '10 min',
  '15 min',
  '20 min',
  '25 min',
  '30 min',
  '40 min',
  '50 min',
  '60 min',
];
export const timeArryValus: number[] = [0, 5, 10, 15, 20, 25, 30, 40, 50, 60];
export const userLevel = {
  contributor: 'contributor',
  common: 'user',
};
export const Subjects: string[] = [
  'Art',
  'English Language Arts',
  'Mathematics',
  'Music',
  'Science',
  'Social Studies',
  'Religious Education',
  'Technology', 
  'History', 
  'Foreign Language'
];
export const targetLevels: string[] = [
  'Kindergarten',
  'Pre-k',
  '1st',
  '2nd',
  '3rd',
  '4th',
  '5th',
  '6th',
  '7th',
  '8th',
  '9th',
  '10th',
  '11th',
  '12th',
  'Higher Ed / Adult',
];
export const memberLevel : string [] = [
  'owner', 'course admin', 'course member'
]