import { Crop } from "react-image-crop";
import { Activity, LessonSection, Member } from "../models";
import { timeArry, timeArryValus } from "../models/defaults";
/**
 *
 Upload Image Process functions
 */
const getBlobFromCanvas = (
  canvas: HTMLCanvasElement,
  fileName: string,
  fType: string
) =>
  new Promise<File>((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        const file = new File([blob], fileName, { type: fType });
        resolve(file);
      } else {
        reject(new Error("Canvas is empty"));
      }
    }, fType); //"image/jpeg");
  });
/**
 * Get file from Crop Image
 * @param orgImage
 * @param url
 * @param vFile
 * @param crop
 * @returns
 */
export const cropImageToFile = async (
  orgImage: HTMLImageElement,
  url: string,
  vFile: File | null,
  crop: Crop
) => {
  let fileName: string | undefined, type: string;
  fileName = "";
  type = "";
  if (vFile != null) {
    fileName = vFile?.name;
    type = vFile?.type;
  } else {
    if (url) {
      fileName = url.split("/").pop();
      const bdy = await fetch(url);
      type = bdy.type;
    }
  }
  const canvas = document.createElement("canvas");
  const scaleX = orgImage.naturalWidth / orgImage.width;
  const scaleY = orgImage.naturalHeight / orgImage.height;
  const ctx = canvas.getContext("2d");
  canvas.width = crop.width * scaleX;
  canvas.height = crop.height * scaleY;
  if (ctx != null) {
    ctx.drawImage(
      orgImage,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
  }
  return await getBlobFromCanvas(canvas, fileName ?? "", type);
};
export const isMyBookmarked = (
  memberId: string | undefined,
  activity: Activity | undefined
) => {
  if (activity && activity._bookmarkedMembers && memberId) {
    if (activity._bookmarkedMembers.find((it) => it.member_Id === memberId))
      return true;
    return false;
  }
  return false;
};
export const isGuidValidate = (uid: string): boolean => {
  let pattern =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return uid.match(pattern) != null;
};
export const hasAdaptItem = (
  sections: LessonSection[],
  curSection: LessonSection | undefined | null,
  userData: Member | null | undefined
) => {
  if (userData && sections && curSection) {
    if (userData._Level < 2) {
      return false;
    }
    if (curSection._activity.activity_Adaptation !== true) {
      if (curSection._activity.isDraft) return false;
      const adaptItem = sections.find(
        (it) =>
          it._activity.orgActivity?.activity_Id ==
            curSection._activity.activity_Id &&
          it._activity.activity_CreatorId === userData.member_Id
      );
      return adaptItem ? true : false;
    }
  }
  return false;
};
export const hasAdaptItemWithActivity = (
  sections: LessonSection[],
  actItem: Activity | undefined | null,
  userData: Member | null | undefined
) => {
  if (userData && sections && actItem) {
    if (userData._Level < 2) {
      return false;
    }
    if (!actItem.activity_Adaptation) {
      const adaptItem = sections.find(
        (it) =>
          it._activity.orgActivity?.activity_Id == actItem.activity_Id &&
          it._activity.activity_CreatorId == userData.member_Id
      );
      return adaptItem ? true : false;
    }
  }
  return false;
};
export const hiddenActivity = (
  sections: LessonSection[],
  curSection: LessonSection,
  userData: Member | null | undefined
) => {
  if (userData && sections && curSection) {
    if (userData._Level < 2) {
      return false;
      // return curSection._activity.activity_Adaptation === true;
    }
    if (curSection._activity.activity_Adaptation !== true) {
      const adaptItem = sections.find(
        (it) =>
          it._activity.orgActivity?.activity_Id ===
            curSection._activity.activity_Id &&
          it._activity.activity_CreatorId === userData.member_Id
      );
      if (
        (adaptItem && adaptItem._activity.isAdaptHidden) ||
        adaptItem === undefined
      ) {
        return false;
      }
    } else {
      if (
        !curSection._activity.isAdaptHidden &&
        curSection._activity.activity_CreatorId === userData.member_Id
      ) {
        return false;
      }
    }
  }
  return true;
};
export const getLessonTime = (sections: LessonSection[], userData: Member) => {
  let lssTime = 0;
  if (sections && userData) {
    sections.forEach((it, idx, data) => {
      if (userData._Level < 2) {
        if (it._activity.activity_Adaptation !== true) {
          const nIndex = timeArry.indexOf(it._activity.activity_UseTime);
          if (nIndex > -1) {
            const timeVal = timeArryValus[nIndex];
            lssTime += timeVal;
          }
        }
      } else {
        if (it._activity.activity_Adaptation !== true) {
          if (it._activity.isDraft) return false;
          const adaptIt = data.find(
            (_it) =>
              _it._activity.activity_Adaptation &&
              _it._activity.orgActivity?.activity_Id ===
                it._activity.activity_Id &&
              _it._activity.activity_CreatorId === userData.member_Id
          );
          if (
            adaptIt?._activity.isAdaptHidden === true ||
            adaptIt === undefined
          ) {
            const nIndex = timeArry.indexOf(it._activity.activity_UseTime);
            if (nIndex > -1) {
              const timeVal = timeArryValus[nIndex];
              lssTime += timeVal;
            }
          }
        } else {
          if (
            it._activity.isAdaptHidden !== true &&
            it._activity.activity_CreatorId === userData.member_Id
          ) {
            const nIndex = timeArry.indexOf(it._activity.activity_UseTime);
            if (nIndex > -1) {
              const timeVal = timeArryValus[nIndex];
              lssTime += timeVal;
            }
          }
        }
      }
    });
  }
  return lssTime;
};
export const isEmpty = (str: string | undefined | null) => {
  return !str || str.trim().length === 0;
};
export const convertContentDefaultImageSize = (content: string) => {
  return content.replace(/style/gi, 'style="max-width: 700px;');
};
export const stripHtmlTags = (html: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  return doc.body.textContent || "";
};
